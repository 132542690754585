<template>
  <div class="postal-code" :key="elmKey" v-bind="initData" ref="postalCode">
    <div class="selected" @click="toggleDropdown">
      <img :class="`flag-icon flag-icon-${selectedCountry.postalCode}`" :src="selectedCountry.flag" :alt="zipCode"/>
      <span class="code">+{{ selectedCountry.postalCode }}</span>
    </div>
    <div v-if="showDropdown" class="dropdown">
      <input type="search" v-model="search" :placeholder="placeHolder" />
      <ul class="countries-list">
        <li v-for="(country, index) in filteredCountries" :key="index" @click="selectCountry(country)" :class="{ 'selected-country': selectedCountry == country }">
          <img :class="`flag-icon flag-icon-${country.postalCode}`" :src="country.flag" :alt="zipCode"/>
          <span class="country">{{ country.name }}</span>
          <span class="code">+{{ country.postalCode }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import api from "../../api";
  import Iconic from "@/plugins/iconic/iconic.vue";

  export default {
    components: {
      Iconic,
    },
    data() {
      return {
        zipCode: "Icono codigo postal",
        countries: [],
        search: "",
        showDropdown: false,
        selectedCountry: {
          name: "",
          flag: "",
          postalCode: "",
        },
        elmKey: 0,
      };
    },
    props: ["currentPostalCode", "placeHolder"],
    mounted() {
      window.addEventListener("click", (e) => {
        const postalCode = this.$refs.postalCode;
        if (this.showDropdown && !postalCode.contains(e.target)) {
          this.showDropdown = false;
        }
      });
    },
    computed: {
      filteredCountries() {
        return this.countries.filter((country) => country.name.toLowerCase().includes(this.search.toLowerCase()));
      },
      initData: async function () {
        await this.getCountriesInfo();
        if (this.currentPostalCode) {
          const code = this.countries.filter((country) => country.postalCode == this.currentPostalCode)[0];

          Vue.set(this, "selectedCountry", code);
        }
      },
    },
    methods: {
      toggleDropdown() {
        this.showDropdown = !this.showDropdown;
      },
      selectCountry(country) {
        this.selectedCountry = country;
        this.showDropdown = false;
        this.$emit("postalCode", country.postalCode, true);
      },
      getCountriesInfo: async function () {
        let response = await api.get(`${process.env.VUE_APP_API_HOST}countries`);
        Object.entries(response.data).map(([key, country]) => {
          if (country.callingCodes?.[0].trim()) {
            country.callingCodes.map((code) => {
              this.countries.push({
                flag: country.flag,
                name: country.name,
                postalCode: code,
              });
            });
          }
        });
        this.selectedCountry = this.countries.filter((country) => country.postalCode == "57")[0];
        this.$emit("postalCode", this.selectedCountry.postalCode, false);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "@/assets/styles/vars.scss";
  img {
    max-width: 6%;
  }
  .postal-code {
    display: inline-block;
    font-family: sans-serif;
  }

  .selected {
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 0.2rem;
    border: 1px solid #ccc;
    cursor: pointer;
    height: 37px;
    img {
      max-width: 30px;
    }
  }

  .selected .flag-icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;
    /* max-width: 10%; */
  }

  .selected .country {
    margin-right: 0.5rem;
  }

  .selected .code {
    color: #aaa;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  .countries-list {
    max-height: 20rem;
    overflow-y: auto;
  }

  .dropdown input {
    display: block;
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
  }

  .dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .dropdown li {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }

  .dropdown li:hover {
    background-color: #f5f5f5;
  }

  .dropdown li.selected-country {
    background-color: $primary_color;
    color: #fff;
  }

  .dropdown .flag-icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }

  .dropdown .country {
    margin-right: 0.5rem;
    flex-grow: 1;
  }

  .dropdown .code {
    color: #aaa;
  }
</style>
