<script>
  import global from "@/assets/js/global.js";
  import { mapGetters } from "vuex";
  export default {
    props: {
      iconSize: {
        type: String,
        default: "30",
      },
      optsLang: {
        type: Array,
        default: () => [],
        validator(value) {
          return value.length > 0;
        },
      },
      currentLocale: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        isHovering: false,
      };
    },
    computed: {
      ...mapGetters(["newLocale"]),
      locale(){
        return this.newLocale || {}
      },
      getCurrentLangDetails() {
        const data = global.getMainlang(this.optsLang, this.currentLocale) || {};

        return {
          flag: data.linkFlag,
          description: data.nameLanguage,
          id: data.languageId,
        };
      },
    },
    methods: {
      toggleDropdown(status) {
        this.isHovering = status ?? !this.isHovering;
      },
      handleLocaleChange(languageId) {
        this.$emit("localeChange", languageId);
      },
    },
  };
</script>
<template>
  <div class="langDropdown" :style="{ '--icon-size': iconSize + 'px' }" @mouseenter="() => toggleDropdown(true)" @mouseleave="() => toggleDropdown(false)" @click="() => toggleDropdown()">
    <div class="langDropdown__selectedLangImgContainer">
      <img class="langDropdown__selectedLangImg" :src="getCurrentLangDetails.flag" :alt="locale.alt.language" />
    </div>
    <ul class="langDropdown__list" v-if="isHovering">
      <li
        :class="`langDropdown__item ${getCurrentLangDetails.id === languageId && 'langDropdown__item--active'}`"
        v-for="({ languageId, linkFlag, nameLanguage }, index) in optsLang"
        :key="languageId"
        @click="handleLocaleChange(languageId)">
        <span>{{ nameLanguage }}</span>
        <span class="langDropdown__flagWrapper">
          <img class="langDropdown__flag" :src="linkFlag" :alt="locale.alt.language" />
        </span>
      </li>
    </ul>
  </div>
</template>
<style lang="scss">
  .langDropdown {
    position: relative;

    &__selectedLangImgContainer {
      @include Flex();
      width: var(--icon-size);
      height: var(--icon-size);
      margin: $space-6;
      border-radius: 100%;
      overflow: hidden;
      cursor: pointer;
    }

    &__selectedLangImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__list {
      width: 140px;
      padding: $space-6;
      border-radius: $radius-8;
      position: absolute;
      right: $space-20;
      background-color: $primary-color;
      box-shadow: 0 0 5px #5b5957;
      color: #fff;
      text-shadow: initial;
    }

    &__item {
      @include Flex(row, space-between, center);
      font-size: 16px;
      padding: $space-6;
      border-radius: $space-8;
      gap: $space-8;
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer;

      &--active {
        background-color: #9e0404;
      }
    }
    &__flagWrapper {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
    &__flag {
      max-height: 100%;
      max-width: 100%;
    }
  }
</style>
